<script setup>
const date = useDateSchema();
const templatePage = useTemplatePage();

useHead({
    meta: [
        {
            name: 'description', content: (() => {
                return 'Проектирование, продажа, установка, ремонт и обслуживание систем кондиционирования и вентиляции в Щёлково, Московской области и Москве. Компания Air Arts входит в число лучших специализированных организаций, с широким ассортиментом товаров и услуг, предназначенных для систем кондиционирования и вентиляции, предоставляющих высокий сервис и удобство обслуживания нашим клиентам.'.slice(0, 159);
            })()
        }
    ],
    script: [{
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
            "@context": "http://schema.org/",
            "@type": "Organization",
            "name": "Air Arts",
            "alternateName": "Воздушное искусство Air Arts",
            "legalName": "ООО Форклифтпарт Партнёр",
            "description": "Проектирование, продажа, установка, ремонт и обслуживание систем кондиционирования и вентиляции в Щёлково, Московской области и Москве.",
            "url": "https://air-arts.ru/",
            "@id": "https://air-arts.ru/",
            "image": "https://air-arts.ru/mainImage.webp",
            "logo": {
                "@type": "ImageObject",
                "@id": "https://air-arts.ru/#logo",
                "url": "https://air-arts.ru/android-chrome-192x192.png",
                "caption": "Air Arts"
            },
            "telephone": "+7-495-143-02-02",
            "email": "info@air-arts.ru",
            "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "telephone": "+7-495-143-02-02",
                    "email": "info@air-arts.ru",
                    "contactType": "Customer service",
                    "areaServed": "RU",
                    "availableLanguage": "Russian"
                }
            ],
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "141104, Россия, Московская область, г. Щёлково, ул. Беляева 7а",
                "addressLocality": "Щёлково",
                "addressRegion": "Московская область",
                "postalCode": "141104",
                "addressCountry": "RU"
            },
            "sameAs": [
                "https://t.me/air_arts",
                "https://vk.com/air__arts",
                "https://air-arts.ru/contacts",
                "https://yandex.ru/profile/97416138360",
                "https://air-arts.clients.site",
                "https://yandex.ru/profile/97416138360?intent=reviews",
                "https://uslugi.yandex.ru/profile/AirArts-2654490?from=suggest&occupationId=%2Fremont-i-stroitel_stvo&specId=%2Fremont-i-stroitel_stvo%2Fventilazia&text=air+arts#reviews"
            ]
        })
    },
    {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "ProfessionalService",
            "additionalType": "https://en.wikipedia.org/wiki/Heating,_ventilation,_and_air_conditioning",
            "name": "Air Arts",
            "image": "https://air-arts.ru/mainImage.webp",
            "@id": "https://air-arts.ru/",
            "url": "https://air-arts.ru/",
            "telephone": "+7-495-143-02-02",
            "priceRange": "800-15000",
            "department": {
                "@type": "Organization",
                "name": "Air Arts",
                "url": "https://air-arts.ru/"
            },
            "review": [
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Любовь"
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Галина А."
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Наталья Г."
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Елена Лахова"
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "наталья т."
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Ника Сурманидзе"
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Инкогнито 3530"
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Кирилл К."
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Александр Анатольевич"
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "GARRY SIDEY"
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Сергей Боров"
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Денис Б."
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Алёна К."
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Наталья Седова"
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Елена Е."
                    }
                },
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "Елена Коршунова"
                    }
                },
            ],
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "bestRating": "5",
                "ratingCount": "16"
            },
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "141104, Россия, Московская область, г. Щёлково, ул. Беляева 7а",
                "addressLocality": "Щёлково",
                "addressRegion": "Московская область",
                "postalCode": "141104",
                "addressCountry": "RU"
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "55.923982",
                "longitude": "38.000907"
            },
            "openingHoursSpecification": [
                {
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                    ],
                    "opens": "9:00",
                    "closes": "18:00"
                }
            ],
            "sameAs": [
                "https://t.me/air_arts",
                "https://vk.com/air__arts",
                "https://air-arts.ru/contacts",
                "https://yandex.ru/profile/97416138360",
                "https://air-arts.clients.site",
                "https://yandex.ru/profile/97416138360?intent=reviews",
                "https://uslugi.yandex.ru/profile/AirArts-2654490?from=suggest&occupationId=%2Fremont-i-stroitel_stvo&specId=%2Fremont-i-stroitel_stvo%2Fventilazia&text=air+arts#reviews"
            ]
        })
    },
    {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            "@id": "https://air-arts.ru/",
            "lastReviewed": date.value,
            "mainContentOfPage": {
                "@type": "WebPageElement",
                "cssSelector": ".main",
                "isAccessibleForFree": true
            },
            "primaryImageOfPage": {
                "@type": "ImageObject",
                "@id": "https://air-arts.ru/mainImage.webp",
                "url": "https://air-arts.ru/mainImage.webp",
                "caption": "Air Arts"
            },
            "mainEntity": {
                "@type": "Service",
                "brand": {
                    "@type": "Brand",
                    "name": "Dahatsu"
                },
                "areaServed": "Щёлково, Московская область, Россия",
                "description": "Проектирование, продажа, установка, ремонт и обслуживание систем кондиционирования и вентиляции в Щёлково, Московской области и Москве. Компания Air Arts входит в число лучших специализированных организаций, с широким ассортиментом товаров и услуг, предназначенных для систем кондиционирования и вентиляции, предоставляющих высокий сервис и удобство обслуживания нашим клиентам.",
                "additionalType": "https://en.wikipedia.org/wiki/Heating,_ventilation,_and_air_conditioning",
                "image": "https://air-arts.ru/mainImage.webp",
                "logo": {
                    "@type": "ImageObject",
                    "@id": "https://air-arts.ru/#logo",
                    "url": "https://air-arts.ru/android-chrome-192x192.png",
                    "caption": "Air Arts"
                },
                "name": "Air Arts",
                "provider": {
                    "@type": "Organization",
                    "name": "Air Arts",
                    "url": "https://air-arts.ru/"
                },
                "url": "https://air-arts.ru/"
            }
        })
    }],
    __dangerouslyDisableSanitizers: ['script'],
});

const SectionMain = resolveComponent(`SectionMain${templatePage.value}`);
const SectionServices = resolveComponent(`SectionServices${templatePage.value}`);
const SectionSolution = resolveComponent(`SectionSolution${templatePage.value}`);
const SectionStep = resolveComponent(`SectionStep${templatePage.value}`);
const SectionApplicationForm = resolveComponent(`SectionApplicationForm${templatePage.value}`);
const SectionComfort = resolveComponent(`SectionComfort${templatePage.value}`);
const SectionSchema = resolveComponent(`SectionSchema${templatePage.value}`);
const SectionPortfolio = resolveComponent(`SectionPortfolio${templatePage.value}`);
const PopUpApplication = resolveComponent(`PopUpApplication${templatePage.value}`);

const popUpApplication = ref(false);

const handlerOpenPopUpApplication = () => {
    document.body.style.overflow = 'hidden';
    popUpApplication.value = true;
};

const handlerClosePopUpApplication = () => {
    document.body.style.overflow = 'unset';
    popUpApplication.value = false;
};
</script>

<template>
    <component :is="SectionMain" @openPopUpApplication="handlerOpenPopUpApplication" />
    <component :is="SectionServices" />
    <component :is="SectionSolution" />
    <component :is="SectionStep" />
    <component :is="SectionApplicationForm" />
    <component :is="SectionComfort" @openPopUpApplication="handlerOpenPopUpApplication" />
    <component :is="SectionSchema" />
    <component :is="SectionPortfolio" />
    <component v-if="popUpApplication" :is="PopUpApplication" @closePopUpApplication="handlerClosePopUpApplication" />
</template>

<style lang="less">
.main {
    &__title {
        width: 1170px;
        font-family: 'Ruberoid';
        font-size: 64px;
        font-weight: 600;
        line-height: 74px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
        margin: 50px auto 20px;

        &-second {
            font-family: 'Ruberoid';
            font-size: 64px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0em;
            position: relative;
            color: rgba(73, 94, 76, 1);
        }

        &-three {
            font-family: 'Ruberoid';
            font-size: 64px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0.03em;
            position: relative;
            color: rgba(120, 192, 129, 1);

        }

        &-four {
            font-family: 'Ruberoid';
            font-size: 64px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0em;
            position: relative;
            color: rgba(73, 94, 76, 1);
        }

        &-five {
            font-family: 'Ruberoid';
            font-size: 64px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0em;
            position: relative;
            color: rgba(73, 94, 76, 1);
            left: 390px;
        }
    }
}
</style>
